import { template as template_96818c6785374b378710e59b79e57b4f } from "@ember/template-compiler";
const EmptyState = template_96818c6785374b378710e59b79e57b4f(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
