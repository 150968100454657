import { template as template_904316d5a0ae45078a118ac01db6c4da } from "@ember/template-compiler";
const FKLabel = template_904316d5a0ae45078a118ac01db6c4da(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
